import Link from 'next/link'
import queryString from 'query-string'
import { useEffect } from 'react'
import { toast } from 'react-toastify'

export function useReferralMessage() {
  useEffect(() => {
    const query = queryString.parse(window.location.search)
    if (query.utm_campaign !== 'referral') return

    let fn = '', ln = ''
    const splitted = query.content && !Array.isArray(query.content) ? query.content.split('_') : null
    if (splitted) {
      fn = splitted[0]
      ln = splitted[1]
    }

    const Text = () => (
      <div>
        {fn && ln ? 'Welcome from ' + fn + ' ' + ln : 'Welcome'}! We retired our referral program, so instead you’ll get our <Link href="https://learn.commitaction.com?coupon=ali-abdaal" passHref><a className='link' target='_blank'>Scientific Productivity</a></Link> course (normally $495) FREE when you get a coach with this special link today.
      </div>
    )

    setTimeout(() => {
      toast.warn(Text, { autoClose: false })
    }, 1000)
  }, [])
}
